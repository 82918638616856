import { IAuthData } from "@/interfaces/auth";

export default function authHeader(): any {
  const authData: IAuthData = JSON.parse(
    localStorage.getItem("authData") ?? ""
  );
  if (authData && authData.access_token) {
    return { Authorization: "Bearer " + authData.access_token };
  } else {
    return {};
  }
}
