
import { computed, defineComponent, onMounted, provide, ref } from "vue";
import { useRouter, useRoute } from "vue-router";

// services
import profileService from "@/services/profile.service";

// interfaces
import { IProfile } from "@/interfaces/auth";

export default defineComponent({
  setup() {
    const router = useRouter();
    const route = useRoute();

    const pageTitle = ref<string>("");
    const setPageTitle = (val: string) => (pageTitle.value = val);

    const isLoading = ref<boolean>(false);
    const setIsLoading = (val: boolean) => (isLoading.value = val);

    const getIsPlainLayout = computed(() => route?.meta?.isPlainLayout);

    provide("setPageTitle", setPageTitle);
    provide("setIsLoading", setIsLoading);

    const menus = [
      // {
      //   label: "Beranda",
      //   path: "/admin/home",
      //   icon: "home.svg",
      // },
      {
        label: "Proyek Saya",
        path: "/admin/home",
        icon: "project.svg",
      },
      {
        label: "Riwayat Pembelian",
        path: "/admin/token-history",
        icon: "order.svg",
      },
      {
        label: "Customize Email",
        path: "/admin/customize-email",
        icon: "email.svg",
      },
    ];

    // get profile
    const initProfile: IProfile = {
      name: "",
      email: "",
      company_name: "",
      token: 0,
    };
    const profileData = ref<IProfile>(initProfile);
    const getProfile = async () => {
      const { data } = await profileService.getProfile();
      profileData.value = data;
    };

    onMounted(() => {
      getProfile();
    });

    provide("getProfile", getProfile);
    provide(
      "token",
      computed(() => profileData.value.token)
    );

    const logout = () => {
      localStorage.clear();
      router.push("/admin/login");
    };

    return {
      logout,
      pageTitle,
      menus,
      profileData,
      getIsPlainLayout,
      isLoading,
    };
  },
});
