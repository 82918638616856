import httpClient from "./http-client";
import authHeader from "./auth-header";
import { AxiosRequestHeaders } from "axios";

const getProfile = async (): Promise<any> => {
  const { data } = await httpClient.get("/b-client/v1/me", {
    headers: authHeader() as AxiosRequestHeaders,
  });

  return data;
};

const getProfileBParticipant = async (): Promise<any> => {
  const { data } = await httpClient.get("/b-participant/v1/profile", {
    headers: authHeader() as AxiosRequestHeaders,
  });

  return data;
};

interface IUpdateProfilePayload {
  name: string;
  place_of_birth: number | null;
  institution_id: number | string | null;
  last_education_from_year: string | Date | null;
  last_education_to_year: string | Date | null;
  profession: string;
  industry_id: number | string | null;
  work_experience_year: number | string | null;
  marital_status: string;
  phone: string;
  date_of_birth: string | null;
  province_id: number | null;
  gender: string;
  last_education: string | null;
  stillEducation?: string;
}

const updateProfile = async (req: IUpdateProfilePayload): Promise<any> => {
  const { data } = await httpClient.put("/b-participant/v1/profile", req, {
    headers: authHeader() as AxiosRequestHeaders,
  });

  return data;
};

export default {
  getProfileBParticipant,
  getProfile,
  updateProfile,
};
